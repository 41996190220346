/* src/pages/CategoryDetail.css */
.category-detail {
    background-image: url('../../assets/splash_bg.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100vh;
    color: black;
    padding: 20px;
    padding-top: 64px;
    box-sizing: border-box;
}

.category-detail h1 {
    font-size: 2rem;
    text-align: center;
    margin: 0;
}

.category-detail h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    text-align: left;
}

.category-detail .sub-category-section {
    margin-bottom: 40px;
}

.category-detail .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
}

.category-detail .product-card {
    position: relative;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    overflow: hidden;
    margin-top: 10px;
    height: 250px;
}

.category-detail .product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.category-detail .black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5
    );
    z-index: 1;
}

.category-detail .product-title {
    position: absolute;
    bottom: 100px; /* Ürün adı ve fiyat arasında boşluk */
    left: 0;
    right: 0;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    padding: 5px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.category-detail .product-price {
    position: absolute;
    bottom: 30px; /* Fiyatı kartın altına yaklaştırdık */
    left: 0;
    right: 0;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loading-message,
.error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
    font-size: 1.5rem;
    color: black;
}

.category-detail .product-sizes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 15px; /* Adjusts the position slightly above the overlay */
    left: 0;
    right: 0;
    z-index: 3; /* Ensures it stays above the overlay and image */
}
.category-detail .product-size {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    text-align: center; /* Center text under the icon */
}

.category-detail .size-icon {
    fill: white;
    width: 30px;
    height: 30px;
    stroke-width: 2px;
    margin-bottom: 5px; /* Add some space between icon and price */
}

.category-detail .size-price {
    font-size: 1rem; /* Match the font size with the price */
    font-weight: 500;
}


