.product-detail {
    padding-top: 64px; /* Space for the AppBar */
}

.product-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
}

.product-image {
    width: 90%; /* Reduce width for spacing on the sides */
    max-width: 600px; /* Maximum width for larger devices */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the area */
    border-radius: 10px;
    background-color: #f0f0f0;
    margin: 20px 0; /* Space below the image */
}

.product-info {
    text-align: center;
    margin-top: 20px; /* Space between the image and product title */
}

.product-title {
    font-size: 2rem; /* Larger font size for titles */
    margin: 10px 0; /* Space between title and price */
}

.product-price {
    font-size: 1.5rem; /* Adjust size as needed */
    font-weight: bold;
    margin: 10px 0; /* Space between price and description */
}

.product-sizes {
    display: flex; /* Use flexbox for horizontal layout */
    justify-content: center; /* Center the items */
    flex-wrap: wrap; /* Allow items to wrap if necessary */
    margin: 10px 0; /* Add some margin for spacing */
}

.product-size {
    display: flex; /* Use flexbox for size icon and price */
    align-items: center; /* Center items vertically */
    margin: 0 10px; /* Space between size options */
}

.product-description {
    background-color: #f9f9f9; /* Light background for the description area */
    border: 1px solid #ddd; /* Border around the description */
    padding: 20px; /* Padding inside the description */
    border-radius: 5px; /* Rounded corners */
    margin: 15px; /* Space above and around the description */
    max-width: calc(100% - 40px); /* Match the image width for consistency */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
}

.size-icon {
    fill: white;
    width: 30px;
    height: 30px;
    stroke-width: 2px;
    margin-bottom: 5px; /* Add some space between icon and price */
}

.size-price {
    font-size: 1rem; /* Match the font size with the price */
    font-weight: 500;
}
