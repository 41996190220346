/* Kategorilerin listeleneceği ana kapsayıcı */
.category-list-container {
    margin-top: 50px; /* AppBar'ın yüksekliğine eşit boşluk ekliyoruz */
    display: flex; /* Flexbox kullanarak merkezi hizalama */
    flex-wrap: wrap; /* Kartların yan yana dizilmesini sağlar */
    justify-content: center; /* Kartları yatayda ortala */
    gap: 5px; /* Kartlar arasındaki boşluk */
    padding: 16px; /* Kapsayıcıya iç boşluk ekle */
    position: relative; /* Logo konumlandırması için */
    min-height: 100vh; /* Sayfanın minimum yüksekliğini belirle */
    background: url('../../assets/splash_bg.png') no-repeat center center fixed; /* Arka plan */
    background-size: cover; /* Arka plan boyutlandırması */
    align-items: center; /* Kartları dikey olarak ortala */
}

/* Logo stili */
.logo {
    position: absolute; /* Logoyu ortalamak için */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Ortalamak için */
    width: 150px; /* Logo boyutu */
}

/* Kart stili */
.category-list-card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    height: auto; /* Kart yüksekliği otomatik ayarlanacak */
    max-width: 250px;
    min-width: 250px; /* Kartın minimum genişliğini ayarla */
}

/* Hover efekti */
.category-list-card:hover {
    transform: none;
}

.image {
    width: 100%;
    height: 150px; /* Resim yüksekliğini belirle */
    object-fit: cover;
    border-radius: 15px 15px 0 0;
    
    max-width: 250px;
}

.categoryName {
    font-size: 16px; /* Yazı boyutunu ayarlama */
    font-weight: bold;
    margin: 5px 0; /* Yukarı ve aşağı boşluk */
    color: #000428;
    text-align: center;
    white-space: nowrap; /* Tek satırda kalmasını sağla */
    overflow: hidden;
    text-overflow: ellipsis; /* Taşan yazının üç nokta ile gösterilmesi */
    max-width: 90%; /* Yazının maksimum genişliğini ayarla */
}

/* Responsive ayarlamalar */
@media (max-width: 900px) {
    .category-list-card {
        height: auto; /* Responsive için yüksekliği otomatik yap */
    }
}

@media (max-width: 600px) {
    .category-list-card {
        height: auto; /* Responsive için yüksekliği otomatik yap */
    }
}
